import React from 'react';
import './Loader.css';
import './HeroSection.css';

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="lds-ripple">
        <div></div>
        <div></div>
        <div></div>

      </div>
      <p className="loader-text">
        Analyzing your Website.... This may take a few seconds, Please wait
      </p>
    </div>
  );
};

export default Loader;

import React, { useContext, useState } from 'react';
import { SEOContext } from './stateContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';
import { motion } from "framer-motion";
import { Button } from "../components/ui/button";
import "./AnimatedLandingPage.css";
import './HeroSection.css';

function LandingPage() {
    const [url, setUrl] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const { setResults } = useContext(SEOContext);
    const navigate = useNavigate();

    const performChecks = async () => {
        setLoading(true);
    //    console.log("Loading started");
        try {
            setError("");
            setResults(null);
            setUrl(url);
            const response = await axios.post(
                process.env.REACT_APP_URL,
                { url }
            );
            setResults(response.data); // Set the response data to context
            navigate('/result');
    //        console.log(response.data);
        } catch (err) {
            setError(err.response?.data?.error || "Error performing check");
        } finally {
            setLoading(false);
    //        console.log("Loading ended"); // Debug log
        }
    };
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            performChecks();
        }
    }
    return (
        <div className="landing-page-container">
            {/* Background decorative elements */}
            <motion.div
                className="decorative-circle top-right-circle"
                animate={{
                    scale: [1, 1.2, 1],
                    x: [0, 20, 0],
                    y: [0, -20, 0],
                }}
                transition={{
                    duration: 8,
                    repeat: Infinity,
                    ease: "easeInOut",
                }}
            />
            <motion.div
                className="decorative-circle bottom-left-circle"
                animate={{
                    scale: [1, 1.2, 1],
                    x: [0, -20, 0],
                    y: [0, 20, 0],
                }}
                transition={{
                    duration: 8,
                    repeat: Infinity,
                    ease: "easeInOut",
                }}
            />

            {/* Dot pattern */}
            <div className="dot-pattern">
                {[...Array(36)].map((_, i) => (
                    <motion.div
                        key={i}
                        className="dot"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: [0, 1, 0] }}
                        transition={{
                            duration: 2,
                            repeat: Infinity,
                            delay: i * 0.05,
                            ease: "easeInOut",
                        }}
                    />
                ))}
            </div>

            {/* Main content */}
            <motion.div
                className="content-container"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, ease: "easeOut" }}
            >
                <motion.h1
                    className="main-heading"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2, duration: 0.8 }}
                >
                    Welcome to SEO Checker
                </motion.h1>
                <motion.p
                    className="main-description"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4, duration: 0.8 }}
                >
                    Analyze and optimize your website's SEO with our powerful tools.
                </motion.p>
                <motion.div
                    className="input-button-container"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.6, duration: 0.8 }}
                >
                    {/* <Input
                        type="email"
                        placeholder="Your best email..."
                        className="email-input"
                    /> */}
                    <input
                        type="text"
                        className="search-input"
                        placeholder="Enter a URL ..."
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        onKeyDown={handleKeyDown}
                    // style={{color: "red"}}
                    />
                    <Button className="get-access-button" onClick={performChecks}>
                        Search
                    </Button>

                </motion.div>

                {/* Decorative image */}
                <motion.div
                    className="decorative-image"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.8, duration: 0.8 }}
                >

                    <motion.div
                        className="inner-rotating-square"
                        animate={{
                            rotate: [0, 360],
                            x: [-50, -70, -50],
                            y: [-80, -60, -80],
                        }}
                        transition={{
                            duration: 8,
                            repeat: Infinity,
                            ease: "linear",
                        }}
                    />
                    <div className="error-loader-container">
                        {error && <p className="error-message">{error}</p>}
                        {loading && <Loader />}
                    </div>
                    <motion.div
                        className="inner-rotating-square"
                        animate={{
                            rotate: [360, 0],
                            x: [50, 70, 50],
                            y: [-80, -60, -80],
                        }}
                        transition={{
                            duration: 8,
                            repeat: Infinity,
                            ease: "linear",
                        }}
                    />
                </motion.div>
            </motion.div>

            {/* Footer */}
            {/* <motion.footer
                className="footer"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1, duration: 0.8 }}
            >
                <p className="footer-text">© Holly</p>
                <div className="footer-links">
                    <a href="#" className="footer-link">Contact</a>
                    <a href="#" className="footer-link">About Us</a>
                    <a href="#" className="footer-link">FAQ's</a>
                    <a href="#" className="footer-link">Support</a>
                </div>
            </motion.footer> */}
        </div>
    );
}
export default LandingPage

import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { useSEO } from "./stateContext";

function BusinessProfile1() {
    const { results } = useSEO();
    const businessProfile = results.googleBusinessProfile || {};
    const location = businessProfile?.data?.geometry?.location || {};
    const lat = location.lat || 0;
    const lng = location.lng || 0;

    const { isLoaded } = useJsApiLoader({
        // googleMapsApiKey: "AIzaSyDaE-a0SH4DYSqnLqj6nFNOItSbkdlCWik",
        googleMapsApiKey : process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

    const [showMarker, setShowMarker] = useState(false);

    useEffect(() => {
        if (isLoaded && lat && lng) {
            const timer = setTimeout(() => {
                setShowMarker(true);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [isLoaded, lat, lng]);

    const truncateText = (text, wordLimit) => {
        const words = text.split(" ");
        return words.length <= wordLimit ? text : words.slice(0, wordLimit).join(" ") + "...";
    };

    const ReviewItem = ({ review }) => {
        const [isExpanded, setIsExpanded] = useState(false);
        const wordLimit = 20;

        return (
            <li style={tss.reviewItem}>
                {isExpanded ? review.text : truncateText(review.text, wordLimit)}{" "}
                {review.text.split(" ").length > wordLimit && (
                    <span onClick={() => setIsExpanded(!isExpanded)} style={tss.toggleTextStyle}>
                        {isExpanded ? " Show Less" : " Read More"}
                    </span>
                )}
                <p style={tss.ratingText}>(Rating: {review.rating})</p>
            </li>
        );
    };

    const rating = businessProfile?.data?.rating || 0;
    const categoryMessage = businessProfile?.filteredCategories?.length > 0 
        ? businessProfile.filteredCategories.join(", ") 
        : "Not available";
    const lowRatingMessage = rating < 3 ? "Rating is low, consider improving." : "";

    return (
        <div style={{ ...tss.cardDesign, ...tss.avoidBreak }}>
            <div style={tss.headerBackground}>
                <h3 style={tss.titleStyle}>Business Profile</h3>
            </div>
            <div style={{...tss.contentWrapper, ...tss.avoidBreak}}>
                <p style={tss.resultStyle}>
                    {businessProfile?.data ? (
                        <div>
                            {/* Google Map and Location */}
                            {isLoaded && lat && lng ? (
                                <GoogleMap
                                    mapContainerStyle={tss.mapContainer}
                                    center={{ lat: lat, lng: lng }}
                                    zoom={15}
                                >
                                    {showMarker && <Marker position={{ lat, lng }} />}
                                </GoogleMap>
                            ) : (
                                <p style={tss.loadingText}>Loading map or no location data available for this business.</p>
                            )}

                            {/* Business Info Section */}
                            <div style={{ ...tss.businessInfoWrapper, ...tss.avoidBreak }}>
                                <div style={tss.businessDetail}>
                                    <p><strong>Business Name:</strong> {businessProfile.data?.name || "Not available"}</p>
                                </div>
                                <div style={tss.businessDetail}>
                                    <p><strong>Category:</strong> {categoryMessage}</p>
                                    {categoryMessage === "Not available" && <p style={{ color: "red" }}>Category is missing.</p>}
                                </div>
                                <div style={tss.businessDetail}>
                                    <p><strong>Address:</strong> {businessProfile.data?.formatted_address || "Not available"}</p>
                                </div>
                                <div style={tss.businessDetail}>
                                    <p><strong>Phone Number:</strong> {businessProfile.data?.formatted_phone_number || "Not available"}</p>
                                </div>
                                <div style={tss.businessDetail}>
                                    <p><strong>Rating:</strong> {rating} stars</p>
                                    {lowRatingMessage && <p style={{ color: "red" }}>{lowRatingMessage}</p>}
                                </div>
                                <div style={tss.businessDetail}>
                                    <p><strong>Total Reviews:</strong> {businessProfile.data?.user_ratings_total || "Not available"}</p>
                                </div>
                            </div>

                            {/* Reviews Section */}
                            <div style={{...tss.reviewsWrapper, ...tss.avoidBreak}}>
                                <h4><strong>Reviews :</strong></h4>
                                <ul style={tss.reviewsList}>
                                    {businessProfile.data.reviews && businessProfile.data.reviews.length > 0 ? (
                                        businessProfile.data.reviews.map((review, index) => (
                                            <ReviewItem key={index} review={review} />
                                        ))
                                    ) : (
                                        <li>No reviews available.</li>
                                    )}
                                </ul>
                            </div>

                            {/* Website Section */}
                            <div style={tss.websiteWrapper}>
                                <p><strong>Website:</strong> {businessProfile.data.website ? 
                                    <a href={businessProfile.data.website} target="_blank" rel="noopener noreferrer" style={tss.linkStyle}>{businessProfile.data.website}</a> : "Not available"}</p>
                            </div>

                            {/* Opening Hours Section */}
                            <div style={{...tss.openingHoursWrapper, ...tss.avoidBreak}}>
                                <h4>Opening Hours</h4>
                                {businessProfile.data.opening_hours?.weekday_text ? (
                                    <table style={tss.table}>
                                        <tbody>
                                            {businessProfile.data.opening_hours.weekday_text.map((day, index) => (
                                                <tr key={index}>
                                                    <td style={tss.tableCell}>{day.split(":")[0]}</td>
                                                    <td style={tss.tableCell}>{day.split("y:")[1]}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <p>Not available</p>
                                )}
                            </div>

                            {/* Status Section */}
                            <div style={tss.statusWrapper}>
                                <p><strong>Status:</strong> {businessProfile.data.opening_hours ? (businessProfile.data.opening_hours.open_now ? "Open Now" : "Closed") : "Not available"}</p>
                            </div>

                            {/* Nearby Businesses Section */}
                            <div style={{ ...tss.nearbyBusinessesWrapper, ...tss.resultStyle, ...tss.avoidBreak }}>
                                <h4><strong>Nearby Businesses of Similar Category</strong></h4>
                                <ul style={tss.nearbyList}>
                                    {businessProfile.nearby && businessProfile.nearby.length > 0 ? (
                                        businessProfile.nearby.map((business, index) => (
                                            <li key={index} style={tss.nearbyItem}>
                                                <p><strong>{business.name}</strong></p>
                                                <p>Address: {business.vicinity}</p>
                                                <p>Rating: {business.rating} stars</p>
                                                <p><strong>Total Reviews:</strong> {business.user_ratings_total}</p>
                                                <p><strong>Website:</strong> {business.website ? (<a style={{ marginBottom: "8px", fontSize: "0.7rem", color: "#007bff" }} href={business.website} target="_blank" rel="noreferrer">{business.website}</a>) : "No Website Found"}</p>
                                            </li>
                                        ))
                                    ) : (
                                        <p>No similar businesses nearby.</p>
                                    )}
                                </ul>
                            </div>
                        </div>
                    ) : (
                        <p style={{ color: 'red' }}>Business profile not found.</p>
                    )}
                </p>
            </div>
        </div>
    );
}

export default BusinessProfile1;

const tss = {
    cardDesign: {
        position: "relative",
        backgroundColor: "#f8f9fa",
        border: "1px solid #dee2e6",
        borderRadius: "8px",
        padding: "20px",
        marginBottom: "16px",
        fontFamily: "'Poppins', sans-serif",
        boxSizing: "border-box",
    },
    headerBackground: {
        borderRadius: '8px 8px 0 0',
        backgroundImage:"linear-gradient(90deg, rgba(5,105,255,1) 0%, rgba(5,105,255,1) 30%, rgba(119,169,245,1) 67%, rgba(200,222,255,1) 100%)",
        display: 'flex',
        color: 'white',
        paddingLeft: '15px',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    businessInfoWrapper: {
        backgroundColor: "#fff",
        borderRadius: "8px",
        padding: "20px",
        marginBottom: "20px",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",  
    },
    businessDetail: {
        marginBottom: "12px",  
        fontSize: "0.7rem",
        lineHeight: "1.5",
        color: "#333",
    },
    titleStyle: {
        fontSize: "1rem",  
        fontWeight: "600",
        // margin: "0",
        // padding: "10px 0",
        // textAlign: "center",
    },
    contentWrapper: {
        padding: "20px 0",
    },
    resultStyle: {
        fontSize: "0.7rem",
        color: "#4b5563",
    },
    reviewsWrapper: {
        marginBottom: "20px",
        fontSize:"0.7rem",
    },
    reviewsList: {
        listStyleType: "none",
        paddingLeft: "20px",
        fontSize: "0.7rem",
    },
    reviewItem: {
        marginBottom: "15px",
        borderBottom: "1px solid #ddd",
        paddingBottom: "10px",
        fontSize: "0.7rem",
        lineHeight: "1.5",
    },
    toggleTextStyle: {
        color: "blue",
        cursor: "pointer",
        fontSize: "0.7rem",
    },
    ratingText: {
        fontSize: "0.7rem",
        color: "#555",
    },
    linkStyle: {
        color: "#007bff",
        textDecoration: "none",
    },
    websiteWrapper: {
        marginBottom: "20px",
    },
    openingHoursWrapper: {
        marginBottom: "20px",
    },
    table: {
        width: "100%",
        borderCollapse: "collapse",
        marginTop: "10px",
    },
    tableCell: {
        padding: "8px",
        border: "1px solid #ddd",
        textAlign: "left",
    },
    statusWrapper: {
        backgroundColor: "#f1f1f1",
        padding: "10px",
        borderRadius: "5px",
        marginBottom: "20px",
    },
    nearbyBusinessesWrapper: {
        marginBottom: "20px",
    },
    nearbyList: {
        listStyleType: "none",
        paddingLeft: "0",
    },
    nearbyItem: {
        marginBottom: "15px",
        padding: "10px",
        border: "1px solid #ddd",
        borderRadius: "5px",
        backgroundColor: "#f9f9f9",
    },
    mapContainer: {
        width: "100%",
        height: "300px",
        borderRadius: "8px",
        marginBottom: "20px",
    },
    loadingText: {
        color: "#888",
        fontSize: "1rem",
    },
    avoidBreak: {
        pageBreakInside: "avoid",
    },
};

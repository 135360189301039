import React from 'react';
import { useSEO } from './stateContext';

const FaviconCheck = () => {
    const { results } = useSEO();

    // Function to render stars based on favicon presence
    const renderStars = (isPresent) => (
        <span style={{ fontSize: '1.5rem', color: '#f39c12', marginLeft: '10px' }}>
            {isPresent ? '★★★★★' : '☆☆☆☆☆'}
        </span>
    );

    // SEO Message for Favicon
    const seoMessage = () => {
        if (results.faviconPresent) {
            return (
                <p style={tss.benefitText}>
                    ✅ Great! Your site has a favicon. A favicon is an important branding element and improves the user experience, especially when users bookmark your site or have multiple tabs open.
                </p>
            );
        } else {
            return (
                <div style={tss.warningContainer}>
                    <span style={tss.warningIcon}>⚠️</span>
                    <span>
                        <strong style={{ color: '#dc3545' }}>No Favicon Found!</strong>
                        <p style={tss.warningText}>
                        ⚠️ A favicon is crucial for branding and usability. Without a favicon, your site may look less professional, and users will have difficulty identifying it in their browser tab or bookmarks. Consider adding a favicon to improve user experience.
                        </p>
                    </span>
                </div>
            );
        }
    };

    return (
        <div style={tss.cardDesigne}>
            <div style={tss.headerBackgrounde}>
                <h3 style={tss.titleStyle}>Favicon Check</h3>
                <div style={{ marginLeft: 'auto', marginRight: '10px' }}>
                    {renderStars(results.faviconPresent)} 
                </div>
            </div>
            <div style={tss.contentWrapper}>
                <p style={tss.resultStyle}>
                    <strong>Favicon Present:</strong> {results.faviconPresent ? "Yes" : "No"}
                    {/* Star rating display */}
                </p>
                {results.faviconPresent && (
                    <div>
                        <img
                            src={results.faviconUrl}
                            alt="Favicon"
                            style={{ width: '24px', height: '24px', marginLeft: '10px' }} // Increased size
                        />
                        <p style={tss.resultStyle}>
                            <strong>Favicon URL:</strong> 
                            <a
                                style={{
                                    color: "#007bff",
                                    textDecoration: "none",
                                }}
                                href={results.faviconUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {results.faviconUrl}
                            </a>
                        </p>
                    </div>
                )}
                {seoMessage()} {/* Display SEO message for Favicon */}
            </div>
        </div>
    );
};

export default FaviconCheck;

const tss = {
    cardDesigne: {
        position: 'relative',
        backgroundColor: '#f8f9fa',
        border: '1px solid #dee2e6',
        borderRadius: '8px',
        padding: '20px',
        marginBottom: '16px',
        fontFamily: "'Poppins', sans-serif",
    },
    headerBackgrounde: {
        borderRadius: '8px 8px 0 0',
        backgroundImage:"linear-gradient(90deg, rgba(5,105,255,1) 0%, rgba(5,105,255,1) 30%, rgba(119,169,245,1) 67%, rgba(200,222,255,1) 100%)",        display: 'flex',
        alignItems: 'center',
        color: 'white',
        paddingLeft: '15px',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    titleStyle: {
        fontSize: '1rem',
        fontWeight: '600',
        margin: '0',
    },
    resultStyle: {
        fontSize: '0.9rem',
        color: '#4b5563',
    },
    contentWrapper: {
        padding: '10px 0',
    },
    warningContainer: {
        display: 'flex',
        alignItems: 'center',
        color: '#dc3545',
    },
    warningIcon: {
        fontSize: '1.5rem',
        marginRight: '10px',
    },
    warningText: {
        fontSize: '0.9rem',
        color: '#4b5563',
        marginTop: '5px',
    },
    benefitText: {
        fontSize: '0.9rem',
        color: '#28a745',
        marginTop: '5px',
    },
    avoidBreak: {
        pageBreakInside: 'avoid',
    },
};

import React from 'react';
import { useSEO } from './stateContext';

const EmailPrivacy = () => {
  const { results } = useSEO();

  return (
    <div style={{ ...tss.cardDesign, ...tss.avoidBreak }}>
      <div style={tss.headerBackground}>
        <h3 style={tss.titleStyle}>Email Privacy</h3>
      </div>
      <div style={tss.contentWrapper}>
        <p style={tss.resultStyle}>
          {results.emailPrivacy ? (
            <div>
              <p style={{ color: "red", fontWeight: "bold" }}>
                ⚠️ Email addresses have been found in plain text:
              </p>
              <ul style={{ listStyleType: "none", padding: "0", marginTop: "10px" }}>
                {results.emailAddresses.map((email, index) => (
                  <li key={index} style={{ marginBottom: "8px", color: "#666" }}>
                    {email}
                  </li>
                ))}
              </ul>
              <p style={tss.warningText}>
                ❌ Having email addresses in plain text exposes them to spam bots and phishing attacks. This negatively impacts user trust and SEO credibility.
              </p>
            </div>
          ) : (
            <div>
              <p style={{ color: "#28a745", fontWeight: "bold" }}>
                ✅ No email addresses found in plain text.
              </p>
              <p style={tss.benefitText}>
                🎉 Great! Keeping email addresses protected improves user trust and reduces the risk of spam, enhancing your website's SEO and security.
              </p>
            </div>
          )}
        </p>
      </div>
    </div>
  );
};

export default EmailPrivacy;

const tss = {
  cardDesign: {
    position: "relative",
    backgroundColor: "#f8f9fa",
    border: "1px solid #dee2e6",
    borderRadius: "8px",
    padding: "20px",
    marginBottom: "16px",
    fontFamily: "'Poppins', sans-serif",
  },
  headerBackground: {
    borderRadius: "8px 8px 0 0",
    backgroundImage:"linear-gradient(90deg, rgba(5,105,255,1) 0%, rgba(5,105,255,1) 30%, rgba(119,169,245,1) 67%, rgba(200,222,255,1) 100%)",    display: "flex",
    color: "white",
    paddingLeft: "15px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  titleStyle: {
    fontSize: "1rem",
    fontWeight: "600",
    margin: "0",
  },
  resultStyle: {
    fontSize: "0.9rem",
    color: "#4b5563",
  },
  contentWrapper: {
    padding: "10px 0",
  },
  warningText: {
    fontSize: "0.9rem",
    color: "#dc3545",
    marginTop: "10px",
  },
  benefitText: {
    fontSize: "0.9rem",
    color: "#28a745",
    marginTop: "10px",
  },
  avoidBreak: {
    pageBreakInside: "avoid",
  },
};

import React from 'react';
import { useSEO } from './stateContext';

const GooglePageSpeed = () => {
  const { results } = useSEO();

  const renderStars = (score) => {
    const rating = Math.round(score / 20); 
    return (
      <span style={{ fontSize: '1.5rem', color: '#f39c12' }}>
        {'★'.repeat(rating)} {/* Filled stars */}
        {'☆'.repeat(5 - rating)} {/* Empty stars */}
      </span>
    );
  };

  const renderImpact = (score) => {
    if (score > 80) {
      return (
        <p style={tss.benefitText}>
          🎉 Your website has excellent speed! This improves user experience, increases engagement, reduces bounce rates, and positively impacts your SEO ranking.
        </p>
      );
    } else if (score > 50) {
      return (
        <p style={tss.warningText}>
          ⚠️ Your website's speed is average. Improving speed could enhance user satisfaction and SEO performance. Consider optimizing images, reducing scripts, and enabling caching.
        </p>
      );
    } else {
      return (
        <p style={tss.errorText}>
          ❌ Your website's speed is below average. Slow speeds can increase bounce rates, lower conversions, and negatively impact your SEO rankings. Optimize your site to boost performance.
        </p>
      );
    }
  };

  return (
    <div style={{ ...tss.cardDesign, ...tss.avoidBreak }}>
      <div style={tss.headerBackground}>
        <h3 style={tss.titleStyle}>Google PageSpeed</h3>
        <div style={{ marginLeft: 'auto', marginRight: '10px' }}>
          {renderStars(results.pageSpeedResults.speed)} {/* Display star rating */}
        </div>
      </div>
      <div style={tss.contentWrapper}>
        <p style={tss.resultStyle}>
          {results.pageSpeedResults && (
            <div>
              <p style={{ fontSize: "0.7rem", color: "#333" }}>
                <strong style={{ fontWeight: "bold" }}>PageSpeed Score:</strong>{" "}
                <span
                  style={{
                    fontWeight: "bold",
                    color:
                      results.pageSpeedResults.speed > 80
                        ? "#28a745"
                        : results.pageSpeedResults.speed > 50
                        ? "#ffc107"
                        : "red",
                  }}
                >
                  {Math.round(results.pageSpeedResults.speed)}%
                </span>
              </p>
              {/* Render SEO impact based on PageSpeed score */}
              {renderImpact(results.pageSpeedResults.speed)}
            </div>
          )}
        </p>
      </div>
    </div>
  );
};

export default GooglePageSpeed;

const tss = {
  cardDesign: {
    position: "relative",
    backgroundColor: "#f8f9fa",
    border: "1px solid #dee2e6",
    borderRadius: "8px",
    padding: "20px",
    marginBottom: "16px",
    fontFamily: "'Poppins', sans-serif",
  },
  headerBackground: {
    borderRadius: "8px 8px 0 0",
    backgroundImage:"linear-gradient(90deg, rgba(5,105,255,1) 0%, rgba(5,105,255,1) 30%, rgba(119,169,245,1) 67%, rgba(200,222,255,1) 100%)",    
    display: "flex",
    color: "white",
    paddingLeft: "15px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  titleStyle: {
    fontSize: "1rem",
    fontWeight: "600",
    margin: "0",
  },
  resultStyle: {
    fontSize: "0.7rem",
    color: "#4b5563",
  },
  contentWrapper: {
    padding: "10px 0",
  },
  avoidBreak: {
    pageBreakInside: "avoid",
  },
  benefitText: {
    fontSize: "0.9rem",
    color: "#28a745",
    marginTop: "10px",
  },
  warningText: {
    fontSize: "0.9rem",
    color: "#ffc107",
    marginTop: "10px",
  },
  errorText: {
    fontSize: "0.9rem",
    color: "#dc3545",
    marginTop: "10px",
  },
};

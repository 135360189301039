import React from 'react';
import { useSEO } from './stateContext';

const DuplicateContent = () => {
    const { results } = useSEO();

    // Determine star rating based on duplicate content detection
    const stars = results.duplicateContentDetected ? 1 : 5;

    // Function to render stars dynamically
    const renderStars = () => (
        <span style={{ fontSize: '1.5rem', color: '#f39c12', marginLeft: '10px' }}>
            {'★'.repeat(stars)}{'☆'.repeat(5 - stars)}
        </span>
    );

    return (
        <div style={{ ...tss.cardDesign, ...tss.avoidBreak }}>
            <div style={tss.headerBackground}>
                <h3 style={tss.titleStyle}>
                    Duplicate Content
                </h3>
                <div style={{ marginLeft: 'auto', marginRight: '10px' }}>
                {renderStars()} </div>
            </div>
            <div style={tss.contentWrapper}>
                <p style={tss.resultStyle}>
                    <p
                        style={{
                            fontSize: "0.7rem",
                            color: results.duplicateContentDetected ? "red" : "#28a745",
                            fontWeight: "bold",
                        }}
                    >
                        {results.duplicateContentDetected
                            ? "Duplicate content detected"
                            : "No duplicate content"}
                    </p>
                </p>
                {results.duplicateContentDetected && (
                    <div>
                        <h4 style={tss.titleStyle}>Duplicate Content Details:</h4>
                        <p style={{ color: "red", fontWeight: "bold" }}>
                            Content appears multiple times, which may affect SEO negatively.
                        </p>
                        <ul>
                            {results.duplicateContentDetails.map((item, index) => (
                                <li key={index}>
                                    "{item.word}" appears {item.count} times.
                                </li>
                            ))}
                        </ul>
                        <p>Consider reviewing your content to eliminate duplicates and improve SEO.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DuplicateContent;

const tss = {
    cardDesign: {
        position: "relative",
        backgroundColor: "#f8f9fa",
        border: "1px solid #dee2e6",
        borderRadius: "8px",
        padding: "20px",
        marginBottom: "16px",
        fontFamily: "'Poppins', sans-serif",
    },
    headerBackground: {
        borderRadius: "8px 8px 0 0",
        backgroundImage:"linear-gradient(90deg, rgba(5,105,255,1) 0%, rgba(5,105,255,1) 30%, rgba(119,169,245,1) 67%, rgba(200,222,255,1) 100%)",        display: "flex",
        alignItems: "center",
        color: "white",
        paddingLeft: "15px",
        paddingTop: "10px", // Added for some top spacing
        paddingBottom: "10px" // Added for some bottom spacing
    },
    titleStyle: {
        fontSize: "1rem",
        fontWeight: "600",
        margin: "0",
    },
    resultStyle: {
        fontSize: "0.7rem",
        color: "#4b5563",
    },
    contentWrapper: {
        padding: "10px 0",
    },
    avoidBreak: {
       pageBreakInside: "avoid",
     },
};

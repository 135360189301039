import React from 'react'
import { createBrowserRouter,RouterProvider } from 'react-router-dom';
import About from './components/About';
import Contact from './components/Contact';
import Navbar from './components/Navbar';
import Result from './components/Result';
import { SEOProvider } from './components/stateContext';
import Navbar1 from './components/NavBar1';
import LandingPage from './components/LandingPage';
const router = createBrowserRouter(
  [
    {
      path: "/",
      element: 
      <>
        <Navbar />
        <LandingPage />
      </>
    },
    {
      path: "/aboutus",
      element: 
      <>
      <Navbar />
      <About />
    </>
    },
    {
      path: "/contact",
      element: 
      <>
      <Navbar />
      <Contact />
    </>
    },
    {
      path: "/result",
      element: 
      <>
      <Navbar1 />
      <Result />
    </>
    }
  ]
)
const App = () => {
  return (
    <>
      <SEOProvider>
       <RouterProvider router={router} />
      </SEOProvider>
    </>
  )
}

export default App
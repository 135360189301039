import React from 'react'

const About = () => {
  return (
  <>

  <p>About</p>
  </>
  )
}

export default About
import React from 'react';
import { useSEO } from './stateContext';

const CodeToTextRatio = () => {
    const { results } = useSEO();

    // Function to determine the message based on the code-to-text ratio
    const getMessage = () => {
        const ratio = results.codeTextRatio;
        if (ratio > 25) {
            return (
                <p>✅ Your page has a healthy code-to-text ratio. A lower ratio is generally better for SEO.</p>
            );
        } else if (ratio > 15) {
            return (
                <p>⚠️ Your page's code-to-text ratio is acceptable, but you may want to optimize it for better performance.</p>
            );
        } else {
            return (
                <div style={tss.warningContainer}>
                    <span style={tss.warningIcon}>⚠️</span>
                    <span>
                        <p>❌ Your page has a very high code-to-text ratio. This might indicate too much code or unnecessary scripts.</p>
                        <p style={tss.warningText}>Consider reducing the amount of code to improve SEO and page performance.</p>
                    </span>
                </div>
            );
        }
    };

    return (
        <div style={{ ...tss.cardDesign, ...tss.avoidBreak }}>
            <div style={tss.headerBackground}>
                <h3 style={tss.titleStyle}>Code-to-Text Ratio</h3>
            </div>
            <div style={tss.contentWrapper}>
                <p style={tss.resultStyle}>
                    <strong style={{ fontWeight: "bold" }}>Code-to-Text Ratio:</strong> {results.codeTextRatio.toFixed(2)}%
                </p>
                {getMessage()}
            </div>
        </div>
    );
};

export default CodeToTextRatio;

const tss = {
    cardDesign: {
        position: "relative",
        backgroundColor: "#f8f9fa",
        border: "1px solid #dee2e6",
        borderRadius: "8px",
        padding: "20px",
        marginBottom: "16px",
        fontFamily: "'Poppins', sans-serif",
    },
    headerBackground: {
        borderRadius: "8px 8px 0 0",
        backgroundImage:"linear-gradient(90deg, rgba(5,105,255,1) 0%, rgba(5,105,255,1) 30%, rgba(119,169,245,1) 67%, rgba(200,222,255,1) 100%)",        display: "flex",
        alignItems: "center",
        color: "white",
        paddingLeft: "15px",
        paddingTop: "10px",
        paddingBottom: "10px",
    },
    titleStyle: {
        fontSize: "1rem",
        fontWeight: "600",
        margin: "0",
    },
    resultStyle: {
        fontSize: "0.7rem",
        color: "#4b5563",
    },
    contentWrapper: {
        padding: "10px 0",
    },
    warningContainer: {
        display: 'flex',
        alignItems: 'center',
        color: '#dc3545',
    },
    warningIcon: {
        fontSize: '1.5rem',
        marginRight: '10px',
    },
    warningText: {
        fontSize: '0.9rem',
        color: '#4b5563',
        marginTop: '5px',
    },
    avoidBreak: {
        pageBreakInside: "avoid",
    },
};

import React from 'react';
import { useSEO } from './stateContext';

const MissingAltTags = () => {
  const { results } = useSEO();

  // Calculate star rating based on missing alt tags
  const missingAltTagsCount = results.missingAltTags ? results.missingAltTags.length : 0;

  // If there are no missing alt tags, give 5 stars
  const altTagRating = missingAltTagsCount === 0 ? 5 :
                       missingAltTagsCount <= 2 ? 4 :
                       missingAltTagsCount <= 5 ? 3 :
                       missingAltTagsCount <= 10 ? 2 : 1;

  // Function to render star icons based on rating
  const renderStars = (rating) => (
    <span style={{ fontSize: '1.5rem', color: '#f39c12' }}> {/* Larger star size */}
      {'★'.repeat(rating)} {/* Filled stars */}
      {'☆'.repeat(5 - rating)} {/* Empty stars */}
    </span>
  );

  // SEO Message for Missing Alt Tags
  const seoMessage = () => {
    if (missingAltTagsCount === 0) {
      return (
        <p style={tss.benefitText}>
          🎉 Great job! All images have alt attributes, improving accessibility and SEO. Alt tags help search engines understand image content and improve user experience.
        </p>
      );
    } else {
      return (
        <div style={tss.warningContainer}>
          <span style={tss.warningIcon}>⚠️</span>
          <span>
            <strong style={{ color: '#dc3545' }}>Missing Alt Tags!</strong>
            <p style={tss.warningText}>
              Alt attributes are important for accessibility and SEO. Adding descriptive alt tags to images helps improve search engine rankings and ensures better user experience for screen readers.
            </p>
          </span>
        </div>
      );
    }
  };

  return (
    <div style={{ ...tss.cardDesign, ...tss.avoidBreak }}>
      <div style={tss.headerBackground}>
        <h3 style={tss.titleStyle}>Missing Alt Tags</h3>
        <div style={{ marginLeft: 'auto', marginRight: '10px' }}>
          {renderStars(altTagRating)} {/* Display star rating here */}
        </div>
      </div>
      <div style={tss.contentWrapper}>
        <p style={tss.resultStyle}>
          {results.missingAltTags && results.missingAltTags.length > 0 ? (
            <>
              <strong style={{ fontWeight: 'bold' }}>Missing Alt Tags:</strong>
              <ul style={{ listStyleType: 'none', padding: '0', marginTop: '10px' }}>
                {results.missingAltTags.map((imgSrc, index) => (
                  <li key={index} style={{ marginBottom: '8px', color: '#666' }}>
                    <a href={imgSrc} target="_blank" rel="noopener noreferrer" style={{ color: '#007bff', textDecoration: 'none' }}>
                      {imgSrc}
                    </a>
                    {/* {' - Missing alt attribute'} */}
                  </li>
                ))}
              </ul>
            </>
          ) : (
            // Display the message when all images have alt tags
            <p style={{ color: '#28a745', fontWeight: 'bold' }}>
              All images have alt attributes.
            </p>
          )}
        </p>
        {seoMessage()} {/* Display SEO message */}
      </div>
    </div>
  );
};

export default MissingAltTags;

const tss = {
  cardDesign: {
    position: 'relative',
    backgroundColor: '#f8f9fa',
    border: '1px solid #dee2e6',
    borderRadius: '8px',
    padding: '20px',
    marginBottom: '16px',
    fontFamily: "'Poppins', sans-serif",
  },
  headerBackground: {
    borderRadius: '8px 8px 0 0',
    backgroundImage:"linear-gradient(90deg, rgba(5,105,255,1) 0%, rgba(5,105,255,1) 30%, rgba(119,169,245,1) 67%, rgba(200,222,255,1) 100%)",
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    paddingLeft: '15px',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  titleStyle: {
    fontSize: '1rem',
    fontWeight: '600',
    margin: '0',
  },
  resultStyle: {
    fontSize: '0.9rem',
    color: '#4b5563',
  },
  contentWrapper: {
    padding: '10px 0',
  },
  warningContainer: {
    display: 'flex',
    alignItems: 'center',
    color: '#dc3545',
  },
  warningIcon: {
    fontSize: '1.5rem',
    marginRight: '10px',
  },
  warningText: {
    fontSize: '0.9rem',
    color: '#4b5563',
    marginTop: '5px',
  },
  benefitText: {
    fontSize: '0.9rem',
    color: '#28a745',
    marginTop: '5px',
  },
  avoidBreak: {
    pageBreakInside: 'avoid',
  },
};

import React from 'react';
import { useSEO } from './stateContext';

const CanonicalTagCheck = () => {
    const { results } = useSEO();

    // Determine star rating based on the presence of a canonical tag
    const stars = results.canonicalTag.status ? 5 : 1;

    // Function to render stars dynamically
    const renderStars = () => (
        <span style={{ fontSize: '1.5rem', color: '#f39c12', marginLeft: '10px' }}>
            {'★'.repeat(stars)}{'☆'.repeat(5 - stars)}
        </span>
    );

    // SEO Message for Canonical Tag
    const seoMessage = () => {
        if (results.canonicalTag.status) {
            return (
                <p style={tss.benefitText}>
                    ✅ Great! This page is using a canonical tag. The canonical tag helps search engines understand the preferred version of a page when duplicate content exists. This improves your site's SEO and prevents potential penalties for duplicate content.
                </p>
            );
        } else {
            return (
                <div style={tss.warningContainer}>
                    <span style={tss.warningIcon}>⚠️</span>
                    <span>
                        <strong style={{ color: '#dc3545' }}>No Canonical Tag Found!</strong>
                        <p style={tss.warningText}>
                            A canonical tag is essential for SEO, especially when your site has pages with duplicate content. Without it, search engines might struggle to determine the primary page, affecting your rankings. Consider adding a canonical tag to prevent duplicate content issues.
                        </p>
                    </span>
                </div>
            );
        }
    };

    return (
        <div style={{ ...tss.cardDesign, ...tss.avoidBreak }}>
            <div style={tss.headerBackground}>
                <h3 style={tss.titleStyle}>Canonical Tag</h3>
                <div style={{ marginLeft: 'auto', marginRight: '10px' }}>
                    {renderStars()}
                </div>
            </div>
            <div style={tss.contentWrapper}>
                <p style={tss.resultStyle}>
                    {results.canonicalTag.status ? (
                        <div>
                            <p>This page is using a Canonical Tag.</p>
                            <strong>Canonical URL:</strong>{" "}
                            <a
                                style={{
                                    color: "#007bff",
                                    textDecoration: "none",
                                }}
                                href={results.canonicalTag.url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {results.canonicalTag.url}
                            </a>
                        </div>
                    ) : (
                        <p>{results.canonicalTag.message}</p>
                    )}
                </p>
                {seoMessage()} {/* Display SEO message for Canonical Tag */}
            </div>
        </div>
    );
};

export default CanonicalTagCheck;

const tss = {
    cardDesign: {
        position: 'relative',
        backgroundColor: '#f8f9fa',
        border: '1px solid #dee2e6',
        borderRadius: '8px',
        padding: '20px',
        marginBottom: '16px',
        fontFamily: "'Poppins', sans-serif",
    },
    headerBackground: {
        borderRadius: '8px 8px 0 0',
        backgroundImage:"linear-gradient(90deg, rgba(5,105,255,1) 0%, rgba(5,105,255,1) 30%, rgba(119,169,245,1) 67%, rgba(200,222,255,1) 100%)",        display: 'flex',
        color: 'white',
        paddingLeft: '15px',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    titleStyle: {
        fontSize: '1rem',
        fontWeight: '600',
        margin: '0',
    },
    resultStyle: {
        fontSize: '0.9rem',
        color: '#4b5563',
    },
    contentWrapper: {
        padding: '10px 0',
    },
    warningContainer: {
        display: 'flex',
        alignItems: 'center',
        color: '#dc3545',
    },
    warningIcon: {
        fontSize: '1.5rem',
        marginRight: '10px',
    },
    warningText: {
        fontSize: '0.9rem',
        color: '#4b5563',
        marginTop: '5px',
    },
    benefitText: {
        fontSize: '0.9rem',
        color: '#28a745',
        marginTop: '5px',
    },
    avoidBreak: {
        pageBreakInside: 'avoid',
    },
};

import React from 'react'

const Contact = () => {
  return (
    <>
    <p>Contact us</p>
    </>
  )
}

export default Contact
import React from 'react';
import { useSEO } from './stateContext';

const HreflangUsage = () => {
    const { results } = useSEO();

    // Determine star rating based on the presence of hreflang tags
    const stars = results.hreflangTags && results.hreflangTags.length > 0 ? 5 : 0;

    // Function to render stars dynamically
    const renderStars = () => (
        <span style={{ fontSize: '1.5rem', color: '#f39c12', marginLeft: '10px' }}>
            {'★'.repeat(stars)}{'☆'.repeat(5 - stars)}
        </span>
    );

    return (
        <div style={{ ...tss.cardDesign, ...tss.avoidBreak }}>
            <div style={tss.headerBackground}>
                <h3 style={tss.titleStyle}>
                    Hreflang Usage
                </h3>
                <div style={{ marginLeft: 'auto', marginRight: '10px' }}>
                    {renderStars()}
                </div>
            </div>
            <div style={tss.contentWrapper}>
                <p style={tss.resultStyle}>
                    {results.hreflangTags && results.hreflangTags.length > 0 ? (
                        <div style={{ marginTop: "20px" }}>
                            <p>✅ Your page is using hreflang tags. These are important for multilingual SEO.</p>
                            <h3>Hreflang Tags</h3>
                            <ul>
                                {results.hreflangTags.map((tag, index) => (
                                    <li key={index}>
                                        <strong>hreflang:</strong> {tag.hreflang} - <strong>URL:</strong> {tag.href}
                                    </li>
                                ))}
                            </ul>
                            <p style={tss.benefitText}>🎉 Great! Hreflang tags help search engines serve the correct language version of your content to users in different regions.</p>
                        </div>
                    ) : (
                        <div style={tss.warningContainer}>
                            <span style={tss.warningIcon}>⚠️</span>
                            <span>
                                <p>❌ Your page is missing hreflang tags.</p>
                                <p style={tss.warningText}>This can negatively impact SEO, especially for international users, as search engines may not know which version of the content to display for different languages and regions.</p>
                            </span>
                        </div>
                    )}
                </p>
            </div>
        </div>
    );
};

export default HreflangUsage;

const tss = {
    cardDesign: {
        position: "relative",
        backgroundColor: "#f8f9fa",
        border: "1px solid #dee2e6",
        borderRadius: "8px",
        padding: "20px",
        marginBottom: "16px",
        fontFamily: "'Poppins', sans-serif",
    },
    headerBackground: {
        borderRadius: "8px 8px 0 0",
        backgroundImage:"linear-gradient(90deg, rgba(5,105,255,1) 0%, rgba(5,105,255,1) 30%, rgba(119,169,245,1) 67%, rgba(200,222,255,1) 100%)",
        display: "flex",
        alignItems: "center",
        color: "white",
        paddingLeft: "15px",
        paddingTop: "10px", // Added for some top spacing
        paddingBottom: "10px" // Added for some bottom spacing
    },
    titleStyle: {
        fontSize: "1rem",
        fontWeight: "600",
        margin: "0",
    },
    resultStyle: {
        fontSize: "0.7rem",
        color: "#4b5563",
    },
    contentWrapper: {
        padding: "10px 0",
    },
    warningContainer: {
        display: 'flex',
        alignItems: 'center',
        color: '#dc3545',
    },
    warningIcon: {
        fontSize: '1.5rem',
        marginRight: '10px',
    },
    warningText: {
        fontSize: '0.9rem',
        color: '#4b5563',
        marginTop: '5px',
    },
    benefitText: {
        fontSize: '0.9rem',
        color: '#28a745',
        marginTop: '5px',
    },
    avoidBreak: {
        pageBreakInside: "avoid",
    },
};
    
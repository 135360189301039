import React from 'react';
import { useSEO } from './stateContext';

const SeoScorePercent = () => {
  const { results } = useSEO();

  // Function to render star icons based on the SEO score
  const renderStars = (score) => {
    const rating = Math.round(score / 20); // Calculate star rating based on score (20 = 1 star)
    return (
      <span style={{ fontSize: '1.5rem', color: '#f39c12' }}>
        {'★'.repeat(rating)} {/* Filled stars */}
        {'☆'.repeat(5 - rating)} {/* Empty stars */}
      </span>
    );
  };

  // Function to render a message based on the SEO score
  const renderSeoMessage = (score) => {
    if (score >= 90) {
      return (
        <p style={tss.benefitText}>
          ✅ **Excellent!** Your SEO score is outstanding. Keep up the great work to maintain high visibility and search engine performance.
        </p>
      );
    } else if (score >= 70) {
      return (
        <p style={tss.warningText}>
          ⚠️ **Good Job!** Your SEO score is solid, but there's still room for improvement. Focus on optimizing specific areas like meta tags, page speed, and mobile usability.
        </p>
      );
    } else {
      return (
        <p style={tss.errorText}>
          ❌ **Needs Improvement!** Your SEO score is below average. Address key issues such as broken links, missing alt tags, or poor content optimization to boost your SEO performance.
        </p>
      );
    }
  };

  return (
    <div style={{ ...tss.cardDesign, ...tss.avoidBreak }}>
      <div style={tss.headerBackground}>
        <h3 style={tss.titleStyle}>SEO Score</h3>
        <div style={{ marginLeft: 'auto', marginRight: '10px' }}>
          {renderStars(results.seoScore)} 
        </div>
      </div>
      <div style={tss.contentWrapper}>
        <p style={tss.resultStyle}>
          <strong style={{ fontWeight: "bold" }}>Score:</strong> {results.seoScore ? results.seoScore.toFixed(2) : "N/A"}%
        </p>
        {/* Render SEO score message */}
        {results.seoScore !== undefined && renderSeoMessage(results.seoScore)}
      </div>
    </div>
  );
};

export default SeoScorePercent;

const tss = {
  cardDesign: {
    position: "relative",
    backgroundColor: "#f8f9fa",
    border: "1px solid #dee2e6",
    borderRadius: "8px",
    padding: "20px",
    marginBottom: "16px",
    fontFamily: "'Poppins', sans-serif",
  },
  headerBackground: {
    borderRadius: "8px 8px 0 0",
    backgroundImage:"linear-gradient(90deg, rgba(5,105,255,1) 0%, rgba(5,105,255,1) 30%, rgba(119,169,245,1) 67%, rgba(200,222,255,1) 100%)",
    display: "flex",
    color: "white",
    paddingLeft: "15px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  titleStyle: {
    fontSize: "1rem",
    fontWeight: "600",
    margin: "0",
  },
  resultStyle: {
    fontSize: "0.7rem",
    color: "#4b5563",
  },
  contentWrapper: {
    padding: "10px 0",
  },
  avoidBreak: {
    pageBreakInside: "avoid",
  },
  benefitText: {
    fontSize: "0.9rem",
    color: "#28a745",
    marginTop: "10px",
  },
  warningText: {
    fontSize: "0.9rem",
    color: "#ffc107",
    marginTop: "10px",
  },
  errorText: {
    fontSize: "0.9rem",
    color: "#dc3545",
    marginTop: "10px",
  },
};

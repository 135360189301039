import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  return (
    <header className="navbar">
      <div className="navbar-content">
      <div className="logo">
          <Link to="/" className="logo-link">
            <span className="logo-text">Crawl My Web</span>
          </Link>
        </div>
        <nav className="nav-links">
          {/* <ul>
            <li><Link to={"/aboutus"}>About</Link></li>
            <li><Link to={"/contact"}>Contact</Link></li>
          </ul> */}
        </nav>
      </div>
    </header>
  );
}

export default Navbar;


import React from 'react';
import './Navbar.css';
import html2pdf from 'html2pdf.js';
import './HeroSection.css';
import { Link, useNavigate } from 'react-router-dom';

const Navbar1 = () => {
    const navigate = useNavigate();

    const downloadPDF = () => {
        const element = document.getElementById('result-content'); // Ensure the correct ID is used
        if (!element) {
            console.error("Element not found");
            return;
        }

        const opt = {
            margin: 1,
            filename: 'seo_results.pdf',
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        };

        html2pdf().from(element).set(opt).save();
    };

    const handleSearchClick = () => {
        navigate("/"); // Adjust this as necessary for your search functionality
    };

    return (
        <header className="navbar">
            <div className="navbar-content">
                <div className="logo">
                    <Link to="/" className="logo-link">
                        <span className="logo-text">Crawl My Web</span>
                    </Link>
                </div>
                <nav className="nav-links">
                    <div style={style.buttonContainer}>
                        <button style={style.searchBtn} onClick={handleSearchClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path fill="#fff" d="M10 2a8 8 0 1 0 5.657 13.657l4.95 4.95a1 1 0 0 0 1.414-1.414l-4.95-4.95A8 8 0 0 0 10 2zm0 2a6 6 0 1 1 0 12 6 6 0 0 1 0-12z"/>
                            </svg>
                        </button>
                        <button style={style.downloadBtn} onClick={downloadPDF}>
                            Download in PDF format
                        </button>
                    </div>
                </nav>
            </div>
        </header>
    );
}

export default Navbar1;

const style = {
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    searchBtn: {
        border: "none",
        borderRadius: "50%",
        cursor: "pointer",
        fontSize: "1rem",
        backgroundColor: "#4285f4",
        color: "#fff",
        padding: "10px",
        marginRight: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    downloadBtn: {
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        fontSize: "1rem",
        backgroundColor: "#34a853",
        color: "#fff",
        padding: "10px 10px",
    },
};

import React from 'react';
import { useSEO } from './stateContext';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import html2pdf from 'html2pdf.js';
import { motion } from 'framer-motion';
import 'react-circular-progressbar/dist/styles.css';
import MissingAltTags from './MissingAltTags';
import XmlSitemap from './XmlSitemap';
import DomainInfo from './DomainInfo';
import SslCertificateDetails from './SslCertificateDetails';
import EmailPrivacy from './EmailPrivacy';
import SocialMediaProfiles from './SocialMediaProfiles';
import Iframes from './Iframes';
import BusinessProfile1 from './BusinessProfile1';
import HeadingTags from './HeadingsTags';
import MetaTagsTnD from './MetaTagsTnD';
import GooglePageSpeed from './GooglePageSpeed';
import SeoScorePercent from './SeoScorePercent';
import BrokenLinks from './BrokenLinks';
import HttpsRedirectCheck from './HttpsRedirectCheck';
import FaviconCheck from './FaviconCheck';
import CanonicalTagCheck from './CanonicalTagCheck';
import HreflangUsage from './HreflangUsage';
import DuplicateContent from './DuplicateContent';
import SuggestedKeywords from './SuggestedKeywords';
import AnalyticsPresence from './AnalyticsPresence';
import RobotsTxt from './RobotsTxt';
import HtmlSitemapCheck from './HtmlSitemapCheck';
import LanguageCheck from './LanguageCheck';
import FlashContent from './FlashContent';
import { Button } from "../components/ui/button";
import "./Results.css";
import './Resultserp.css';
import CodeToTextRatio from './CodeToTextRatio';
// import './HeroSection.css';
// import "./CardDesign.css"
const Result = () => {
  const { results } = useSEO();
  const getColorByPercentage = (value) => {
    if (value >= 90) return '#28a745'; // Green for 90-100
    else if (value >= 75) return '#ffc107'; // Yellow for 75-89
    else if (value >= 50) return '#dc3545'; // Red for 50-74
    return '#dc3545'; // Red for below 50
  };

  const seoScore = results.seoScore || 0;
  const usabilityScore = Math.min(Math.max(results.usabilityScore || 0, 0), 100);
  const performanceScore = Math.min(Math.max(Math.round(results.pageSpeedResults.speed) || 0, 0), 100);
  const totalScores = [seoScore, usabilityScore, performanceScore];
  const validScores = totalScores.filter(score => !isNaN(score) && score >= 0);

  let overallScore = 0;
  if (validScores.length > 0) {
    overallScore = (validScores.reduce((acc, score) => acc + score, 0) / validScores.length);
  }
  const overallScoreNumeric = parseFloat(overallScore.toFixed(2));

  const getMessageByScore = (score) => {
    if (score >= 90) {
      return "Your page is excellent! Keep it up!";
    } else if (score >= 75) {
      return "Great job! Your page could be better with minor adjustments.";
    } else if (score >= 50) {
      return "Your page is good, but there are opportunities for enhancement.";
    } else {
      return "Your page could be better. Consider optimizing more.";
    }
  };

  // Function to render a simple bar graph based on the frequency
  const renderGraph = (frequency) => {
    const barStyle = {
      height: '10px',
      width: `${frequency * 10}px`, // Scale width based on frequency
      backgroundColor: '#3b82f6',
      borderRadius: '5px',
    };
    return <div style={barStyle} />;
  };

  let formattedUrl;
  if (results.sslDetails.valid) {
    formattedUrl = results.url && (results.url.startsWith('http://') || results.url.startsWith('https://'))
      ? results.url.replace(/^http:\/\//, 'https://')
      : `https://${results.url || 'default-url.com'}`;
  } else {
    formattedUrl = results.url && (results.url.startsWith('http://') || results.url.startsWith('https://'))
      ? results.url
      : `https://${results.url || 'default-url.com'}`;
  }
  const getSERPPreview = () => {
    if (!results) return null;
    const { title = "No title available", metaDescription = "No description available" } = results;
    return (
      <div className="serp-preview-container">
        <div className="serp-preview-url">{formattedUrl}</div>
        <div className="serp-preview-title">{title}</div>
        <div className="serp-preview-description">{metaDescription}</div>
      </div>
    );
  };
  const getSERPPreviewMobile = () => {
    if (!results) return null;
    const { title = "No title available", metaDescription = "No description available" } = results;
    return (
      <div className="serp-preview-mobile-container">
        <div className="serp-preview-mobile-url">{formattedUrl}</div>
        <div className="serp-preview-mobile-title">{title}</div>
        <div className="serp-preview-mobile-description">{metaDescription}</div>
      </div>
    );
  };
  const downloadPDF = () => {
    const element = document.getElementById('result-content');
    if (!element) {
      console.error("Element not found");
      return;
    }
    const opt = {
      margin: 1,
      filename: 'seo_results.pdf',
      html2canvas: { scale: 6 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }, // Prevent page breaks inside elements
    };
    html2pdf().from(element).set(opt).save();
  };
 

  return (
    <div className="landing-page-container1">
      {/* Background decorative elements */}
      <motion.div
        className="decorative-circle1 top-right-circle1"
        animate={{
          scale: [1, 1.2, 1],
          x: [0, 20, 0],
          y: [0, -20, 0],
        }}
        transition={{
          duration: 8,
          repeat: Infinity,
          ease: "easeInOut",
        }}
      />
      <motion.div
        className="decorative-circle1 bottom-left-circle1"
        animate={{
          scale: [1, 1.2, 1],
          x: [0, -20, 0],
          y: [0, 20, 0],
        }}
        transition={{
          duration: 8,
          repeat: Infinity,
          ease: "easeInOut",
        }}
      />

      {/* Dot pattern */}
      <div className="dot-pattern1">
        {[...Array(36)].map((_, i) => (
          <motion.div
            key={i}
            className="dot1"
            initial={{ opacity: 0 }}
            animate={{ opacity: [0, 1, 0] }}
            transition={{
              duration: 2,
              repeat: Infinity,
              delay: i * 0.05,
              ease: "easeInOut",
            }}
          />
        ))}
      </div>
      {/* Main content */}
      <motion.div
        className="content-container1"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <div id='result-content' style={{
          marginTop: "20px", marginLeft: "30px", marginRight: "10px", fontFamily: "'Poppins', sans-serif",
        }}>
          <div style={{ ...tss.cardDesign, ...tss.avoidBreak }}>
            <div style={tss.headerBackground}>
              <h3 style={tss.titleStyle}>Website Report for {formattedUrl}</h3>
            </div>
            <div style={tss.contentWrapper}>
              <p style={tss.resultStyle}>
                This report grades your website based on the strength of various SEO factors such as On Page Optimization, Off Page Links, Social and more. Improving your SEO will generally make your website perform better for users and rank better in search engines. There are recommendations for improving your website at the bottom of the report. Feel free to reach out of us if you'd like us to help with improving your website's SEO!
              </p>
            </div>
          </div>

          <div style={{ ...tss.cardDesign, ...tss.avoidBreak }}>
            <div style={tss.headerBackground}>
              <h3 style={tss.titleStyle}>Audit Result for {formattedUrl}</h3>
            </div>
            <div style={tss.contentWrapper}>
              <p style={tss.resultStyle}>
                <strong style={{ fontWeight: "bold" }}></strong> <div id='result-content' style={{ marginTop: '20px', marginLeft: '30px', marginRight: '10px', fontFamily: "'Poppins', sans-serif" }}>

                  <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', marginBottom: '40px' }}>
                    <div style={{ width: '130px', margin: '20px' }}>
                      <h4>SEO Score</h4>
                      <CircularProgressbar
                        value={seoScore}
                        text={`${seoScore}%`}
                        styles={buildStyles({ textColor: 'black', pathColor: getColorByPercentage(seoScore) })}
                      />
                    </div>

                    <div style={{ width: '130px', margin: '20px' }}>
                      <h4>Usability</h4>
                      <CircularProgressbar
                        value={usabilityScore}
                        text={`${usabilityScore}%`}
                        styles={buildStyles({ textColor: 'black', pathColor: getColorByPercentage(usabilityScore) })}
                      />
                    </div>

                    <div style={{ width: '130px', margin: '20px' }}>
                      <h4>Performance</h4>
                      <CircularProgressbar
                        value={performanceScore}
                        text={`${performanceScore}%`}
                        styles={buildStyles({ textColor: 'black', pathColor: getColorByPercentage(performanceScore) })}
                      />
                    </div>
                  </div>

                  <div style={{ width: '150px', margin: 'auto', marginTop: '20px' }}>
                    <h4>Overall Score</h4>
                    <CircularProgressbar
                      value={overallScoreNumeric}
                      text={`${overallScoreNumeric}%`}
                      styles={buildStyles({ textColor: 'black', pathColor: getColorByPercentage(overallScoreNumeric) })}
                    />
                  </div>
                  <p style={{ textAlign: 'center', marginTop: '10px' }}>
                    {getMessageByScore(overallScoreNumeric)}
                  </p>
                </div>

              </p>
            </div>
          </div>

          <BusinessProfile1 />

          <MetaTagsTnD />
          <div style={{ ...tss.cardDesign, ...tss.avoidBreak }}>
            <div style={tss.headerBackground}>
              <h3 style={tss.titleStyle}>Search Engine Results Page (SERP)
              </h3>
            </div>
            <div style={tss.contentWrapper}>
              <p style={tss.resultStyle}>
                <strong style={{ fontWeight: "bold" }}>SERP for Desktop:</strong> {getSERPPreview()}
              </p>
            </div>
            <div style={tss.contentWrapper}>
              <p style={tss.resultStyle}>
                <strong style={{ fontWeight: "bold" }}>SERP for Mobile:</strong>   {getSERPPreviewMobile()}
              </p>
            </div>
          </div>


          <HeadingTags />
          <div style={{ ...tss.cardDesign, ...tss.avoidBreak }}>
            <div style={tss.headerBackground}>
              <h3 style={tss.titleStyle}>Headings</h3>
            </div>
            <div style={tss.contentWrapper}>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th style={tss.tableHeader}>Heading Tag</th>
                    <th style={tss.tableHeader}>Frequency</th>
                    <th style={tss.tableHeader}>Graph</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(results.headings).map((tag, index) => (
                    <tr key={index}>
                      <td style={tss.tableCell}>{tag.toUpperCase()}</td>
                      <td style={tss.tableCell}>{results.headings[tag].length}</td>
                      <td style={tss.tableCell}>{renderGraph(results.headings[tag].length)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <CodeToTextRatio />
          {/* <GoogleCache /> */}
          <FlashContent />
          <Iframes />
          <DomainInfo />
          <FaviconCheck />
          <CanonicalTagCheck />
          <LanguageCheck />
          <HreflangUsage />
          <XmlSitemap />
          <HtmlSitemapCheck />
          <RobotsTxt />
          <SslCertificateDetails />
          <EmailPrivacy />
          <MissingAltTags />
          <DuplicateContent />
          <SocialMediaProfiles />
          <GooglePageSpeed />
          <AnalyticsPresence />
          <SeoScorePercent />
          <HttpsRedirectCheck />
          <BrokenLinks />
          <SuggestedKeywords />
          <Button className="btn search-btn" onClick={downloadPDF} style={{ marginTop: "20px", margin: "20px 0px 20px", padding: "10px 20px", cursor: "pointer" }}>
            Download in PDF format
          </Button>
          {/* </div> */}
          {/* {console.log(results)} */}
        </div>
          </motion.div>

    </div>

  );
};

export default Result;

const tss = {
  cardDesign: {
    position: "relative",
    backgroundColor: "#f8f9fa",
    border: "1px solid #dee2e6",
    borderRadius: "8px",
    padding: "20px",
    marginBottom: "16px",
    fontFamily: "'Poppins', sans-serif",
  },
  headerBackground: {
    borderRadius: "8px 8px 0 0",
    backgroundImage:"linear-gradient(90deg, rgba(5,105,255,1) 0%, rgba(5,105,255,1) 30%, rgba(119,169,245,1) 67%, rgba(200,222,255,1) 100%)",
    display: "flex",
    color: "white",
    paddingLeft: "15px",
    paddingTop: "10px", // Added for some top spacing
    paddingBottom: "10px" // Added for some bottom spacing
  },
  titleStyle: {
    fontSize: "1.1rem", // Adjusted for better clarity
    fontWeight: "600",
    margin: "0",
  },
  resultStyle: {
    fontSize: "0.8rem", // Increased for better readability
    color: "#4b5563",
  },
  contentWrapper: {
    padding: "10px 0",
  },
  avoidBreak: {
    pageBreakInside: "avoid",
  },
  tableContainer: {
    width: "100%",
    overflowX: "auto", // Helps with responsive layout
    marginBottom: "20px", // Space between sections
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  tableHeader: {
    padding: "10px",
    textAlign: "center", // Center aligned
    fontWeight: "bold",
    backgroundColor: "#f1f1f1",
    // border: "1px solid #ddd",
  },
  tableCell: {
    padding: "8px",
    textAlign: "center", // Center aligned for consistency
    border: "1px solid #ddd",
  },
  graphContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  graphBar: {
    height: "10px",
    backgroundColor: "#3b82f6",
    borderRadius: "5px",
    margin: "auto", // Center the bar in the column
  },
};

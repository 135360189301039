import React from 'react'; 
import { useSEO } from './stateContext';

const MetaTagsTnD = () => {
  const { results } = useSEO();

  // Function to calculate star rating based on the status of title and description length checks
  const calculateMetaRating = (status) => {
    if (status === 'Pass') return 5;
    if (status === 'Warning') return 3;
    return 1;
  };

  // Define ratings for title and description based on their respective status
  const titleRating = calculateMetaRating(results?.titleLengthCheck?.status);
  const descriptionRating = calculateMetaRating(results?.metaDescriptionLengthCheck?.status);

  // Function to render star icons based on rating
  const renderStars = (rating) => (
    <span style={{ fontSize: '1.5rem', color: '#f39c12' }}> {/* Adjust font size and color */}
      {'★'.repeat(rating)}
      {'☆'.repeat(5 - rating)}
    </span>
  );

  return (
    <div style={{ ...tss.cardDesign, ...tss.avoidBreak }}>
      <div style={tss.headerBackground}>
        <h3 style={tss.titleStyle}>Meta Tags (Title and Description)</h3>
      </div>
      <div style={tss.contentWrapper}>
        <p style={tss.resultStyle}>
          <ul style={{ listStyleType: "none", padding: "0" }}>
            {results && (
              <div>
                <li style={{ marginBottom: "20px", display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <strong>Title:</strong> {results.title}
                    <p style={{ margin: "5px 0", fontSize: "0.7rem", color: "#666" }}>
                      <strong>Title Length Check:</strong> {results.titleLengthCheck.message} (Length:{" "}
                      <span style={{ fontWeight: "bold" }}>{results.titleLengthCheck.length}</span>)
                      <span style={{ marginLeft: "10px", fontSize: "0.7rem", fontWeight: 'bold', color: results.titleLengthCheck.status === 'Pass' ? 'green' : 'red' }}>
                        {results.titleLengthCheck.status}
                      </span>
                    </p>
                  </div>
                  <div>{renderStars(titleRating)}</div>
                </li>
                <li style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <strong>Meta Description:</strong> {results.metaDescription}
                    <p style={{ margin: "5px 0", fontSize: "0.7rem", color: "#666" }}>
                      <strong>Description Length Check:</strong> {results.metaDescriptionLengthCheck.message} (Length:{" "}
                      <span style={{ fontWeight: "bold" }}>{results.metaDescriptionLengthCheck.length}</span>)
                      <span style={{ marginLeft: "10px", fontWeight: 'bold', fontSize: "0.7rem", color: results.metaDescriptionLengthCheck.status === 'Pass' ? 'green' : 'red' }}>
                        {results.metaDescriptionLengthCheck.status}
                      </span>
                    </p>
                  </div>
                  <div>{renderStars(descriptionRating)}</div>
                </li>
              </div>
            )}
          </ul>
        </p>
      </div>
    </div>
  );
};

export default MetaTagsTnD;

const tss = {
  cardDesign: {
    position: "relative",
    backgroundColor: "#f8f9fa",
    border: "1px solid #dee2e6",
    borderRadius: "8px",
    padding: "20px",
    marginBottom: "16px",
    fontFamily: "'Poppins', sans-serif",
  },
  headerBackground: {
    borderRadius: "8px 8px 0 0",
    backgroundImage:"linear-gradient(90deg, rgba(5,105,255,1) 0%, rgba(5,105,255,1) 30%, rgba(119,169,245,1) 67%, rgba(200,222,255,1) 100%)",
    display: "flex",
    color: "white",
    paddingLeft: "15px",
    paddingTop: "10px", // Added for some top spacing
    paddingBottom: "10px" // Added for some bottom spacing
  },
  titleStyle: {
    fontSize: "1rem",
    fontWeight: "600",
    margin: "0",
  },
  resultStyle: {
    fontSize: "0.7rem",
    color: "#4b5563",
  },
  contentWrapper: {
    padding: "10px 0",
  },
  avoidBreak: {
    pageBreakInside: "avoid",
  },
};

import React from 'react';
import { useSEO } from './stateContext';

const BrokenLinks = () => {
  const { results } = useSEO();

  // Function to calculate star rating based on the number of broken links
  const calculateBrokenLinksRating = (numBrokenLinks) => {
    if (numBrokenLinks === 0) return 5;
    if (numBrokenLinks <= 2) return 4;
    if (numBrokenLinks <= 5) return 3;
    if (numBrokenLinks <= 10) return 2;
    return 1;
  };

  // Number of broken links found
  const brokenLinksCount = results.brokenLinks ? results.brokenLinks.length : 0;
  const brokenLinksRating = calculateBrokenLinksRating(brokenLinksCount);

  // Function to render stars based on rating
  const renderStars = (rating) => (
    <span style={{ fontSize: '1.5rem', color: '#f39c12' }}>
      {'★'.repeat(rating)}
      {'☆'.repeat(5 - rating)}
    </span>
  );

  // Function to render the impact message
  const renderImpactMessage = (count) => {
    if (count === 0) {
      return (
        <p style={tss.benefitText}>
          ✅ **Great Job!** No broken links found. This ensures a seamless user experience, improves crawl efficiency for search engines, and positively impacts your SEO.
        </p>
      );
    } else {
      return (
        <p style={tss.errorText}>
          ❌ **Issues Detected!** Found {count} broken link{count > 1 ? "s" : ""}. Broken links can frustrate users, reduce site credibility, and negatively impact SEO. Make sure to fix these links to improve performance.
        </p>
      );
    }
  };

  return (
    <div style={{ ...tss.cardDesign, ...tss.avoidBreak }}>
      <div style={tss.headerBackground}>
        <h3 style={tss.titleStyle}>Broken Links</h3>
        <div style={{ marginLeft: 'auto', marginRight: '10px' }}>{renderStars(brokenLinksRating)}</div>
      </div>
      <div style={tss.contentWrapper}>
        <p style={tss.resultStyle}>
          <strong style={{ fontWeight: "bold" }}>Broken Links:</strong>{" "}
          {brokenLinksCount > 0 ? (
            <ul style={{ listStyleType: "none", padding: "0", marginTop: "10px" }}>
              {results.brokenLinks.map((link, index) => (
                <li key={index} style={{ marginBottom: "8px", color: "red" }}>
                  {link}
                </li>
              ))}
            </ul>
          ) : (
            <p style={{ color: "#28a745" }}>No broken links found.</p>
          )}
        </p>
        {/* Render the impact message */}
        {renderImpactMessage(brokenLinksCount)}
      </div>
    </div>
  );
};

export default BrokenLinks;

const tss = {
  cardDesign: {
    position: "relative",
    backgroundColor: "#f8f9fa",
    border: "1px solid #dee2e6",
    borderRadius: "8px",
    padding: "20px",
    marginBottom: "16px",
    fontFamily: "'Poppins', sans-serif",
  },
  headerBackground: {
    borderRadius: "8px 8px 0 0",
    backgroundImage:"linear-gradient(90deg, rgba(5,105,255,1) 0%, rgba(5,105,255,1) 30%, rgba(119,169,245,1) 67%, rgba(200,222,255,1) 100%)",
    display: "flex",
    color: "white",
    paddingLeft: "15px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  titleStyle: {
    fontSize: "1rem",
    fontWeight: "600",
    margin: "0",
  },
  resultStyle: {
    fontSize: "0.7rem",
    color: "#4b5563",
  },
  contentWrapper: {
    padding: "10px 0",
  },
  avoidBreak: {
    pageBreakInside: "avoid",
  },
  benefitText: {
    fontSize: "0.9rem",
    color: "#28a745",
    marginTop: "10px",
  },
  errorText: {
    fontSize: "0.9rem",
    color: "#dc3545",
    marginTop: "10px",
  },
};

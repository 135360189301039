import React from 'react';
import { useSEO } from './stateContext';

const HeadingsTags = () => {
    const { results } = useSEO();

    return (
        <div style={{ ...tss.cardDesign, ...tss.avoidBreak }}>
            <div style={tss.headerBackground}>
                <h3 style={tss.titleStyle}>Headings</h3>
            </div>
            <div style={tss.contentWrapper}>
                {Object.keys(results.headings).map((tag, index) => {
                    const isMultiple = results.headings[tag].length > 1;

                    return (
                        <div key={index} style={tss.tagWrapper}>
                            <h4 style={{ ...tss.tagTitleStyle, color: isMultiple ? 'red' : 'inherit' }}>
                                {tag.toUpperCase()} ({results.headings[tag].length})
                            </h4>
                            <ul style={tss.listStyle}>
                                {results.headings[tag].map((content, idx) => (
                                    <li key={idx} style={tss.listItemStyle}>{content}</li>
                                ))}
                            </ul>
                            {/* Show error message if tag is used more than once */}
                            {isMultiple && (
                                <p style={tss.warningStyle}>
                                    ⚠️ {tag.toUpperCase()} tag is used more than once.
                                </p>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default HeadingsTags;

const tss = {
    cardDesign: {
        position: "relative",
        backgroundColor: "#f8f9fa",
        border: "1px solid #dee2e6",
        borderRadius: "8px",
        padding: "20px",
        marginBottom: "16px",
        fontFamily: "'Poppins', sans-serif",
    },
    headerBackground: {
        borderRadius: "8px 8px 0 0",
        backgroundImage:"linear-gradient(90deg, rgba(5,105,255,1) 0%, rgba(5,105,255,1) 30%, rgba(119,169,245,1) 67%, rgba(200,222,255,1) 100%)",
        display: "flex",
      //  justifyContent: "center",  // Center the title horizontally
        alignItems: "center",      // Center vertically if needed
        color: "white",
        padding: "15px",
        paddingTop: "10px", // Added for some top spacing
        paddingBottom: "10px" // Added for some bottom spacing
    },
    titleStyle: {
        fontSize: "1.2rem",   // Slightly larger font size for emphasis
        fontWeight: "600",    // Bold for title
        margin: "0",          // Remove default margin
    },
    contentWrapper: {
        padding: "20px 0",  // Add more padding for better spacing
    },
    tagWrapper: {
        marginBottom: "20px",  // Add space between each tag's section
        padding: "10px 0",
        borderBottom: "1px solid #ddd",  // Optional: Add a subtle divider between sections
    },
    tagTitleStyle: {
        fontSize: "0.7rem",    // Slightly larger for tag title
        fontWeight: "500",     // Regular weight for tag name
        margin: "0",           // Remove margin for tight spacing
        textAlign: "left",     // Left-align the tag titles
    },
    listStyle: {
        paddingLeft: "20px",  // Add indentation to the list items
        marginTop: "10px",    // Add some space before the list starts
    },
    listItemStyle: {
        fontSize: "0.9rem",   // Set the font size for list items
        lineHeight: "1.5",     // Make list items more readable
        color: "#333",         // Set a standard text color
    },
    warningStyle: {
        color: "red",
        fontSize: "0.8rem",     // Slightly smaller font for warnings
        marginTop: "10px",      // Add some space before the warning
    },
    avoidBreak: {
        pageBreakInside: "avoid",
    },
};

    import React, { createContext, useState } from 'react';
    export const SEOContext = createContext();
    export const SEOProvider = ({ children }) => {
    const [results, setResults] = useState(null);
    const [url, setUrl] = useState('');
    
    return (
        <SEOContext.Provider value={{ results, setResults,setUrl }}>
        {children}
        </SEOContext.Provider>
    );
    };

    export const useSEO = () => {
    return React.useContext(SEOContext);
    };

 